import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Button,
  CardBody,
  Chip,
  CardFooter,
  Input,
  Alert,
} from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import "../../../App.css";
import {
  deleteUser,
  getNews,
  getProduct,
} from "../../../context/actions/getaction";

const TABLE_HEAD = ["الاسم", "USD", "IQD", "التاريخ", "تعديل / حذف"];

function Reports() {
  const { product } = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const { news } = useSelector((state) => state.news);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  // حالة البحث
  const [searchQuery, setSearchQuery] = useState("");

  // حالة الصفحة الحالية
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // دالة التحكم في البحث
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  // تصفية البيانات بناءً على البحث
  const filteredData = news.filter((item) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      item.name.toLowerCase().includes(lowerCaseQuery) ||
      item.type?.toLowerCase().includes(lowerCaseQuery) ||
      item.price?.toString().includes(lowerCaseQuery)
    );
  });

  // ترتيب البيانات حسب التاريخ الأحدث
  const sortedData = filteredData.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  // حساب البيانات المعروضة بناءً على الصفحة الحالية
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // دالة للتنقل بين الصفحات
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= Math.ceil(sortedData.length / itemsPerPage)) {
      setCurrentPage(newPage);
    }
  };
  useEffect(() => {
    if (success !== null) {
      const timer = setTimeout(() => {
        setSuccess(null);
        setError("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  return (
    <Card dir="rtl" className="black-foto">
      <div className="p-4">
        <Input
          color="white"
          size="lg"
          placeholder="أبحث"
          className="!border-t-blue-gray-200 font-tajawal text-right"
          labelProps={{
            className: "before:content-none after:content-none",
          }}
          icon={<MagnifyingGlassIcon className="h-5 w-5 " />}
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      {success === true ? (
        <div dir="rtl" className="flex w-full flex-col gap-2">
          <Alert className="font-tajawal text-center" color="green">
            تم الحذف بنجاح.
          </Alert>
        </div>
      ) : success === false && error ? (
        <div dir="rtl" className="flex w-full flex-col gap-2">
          <Alert className="font-tajawal items-center" color="red">
            {error}
          </Alert>
        </div>
      ) : null}
      <CardBody className="overflow-scroll px-0">
        <table className="w-full min-w-max table-auto mx-2">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th key={head} className="px-2 pb-4 text-center">
                  <Typography
                    variant="small"
                    color="white"
                    className="font-bold leading-none opacity-70 font-tajawal bg-[#253151] p-2 rounded-lg"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => {
              const data = product.filter(
                (e) => e.user_id?.objectId === item.objectId
              );
              const USD1 = data.filter(
                (e) => e.currency === "USD" && e.type == 0
              );
              const USD2 = data.filter(
                (e) => e.currency === "USD" && e.type == 1
              );
              const totalUSD1 = USD1.reduce((sum, e) => sum + e.price, 0);
              const totalUSD2 = USD2.reduce((sum, e) => sum + e.price, 0);
              const totalUSD = totalUSD1 - totalUSD2;

              const IQD1 = data.filter(
                (e) => e.currency === "IQD" && e.type == 0
              );
              const IQD2 = data.filter(
                (e) => e.currency === "IQD" && e.type == 1
              );
              const totalIQD1 = IQD1.reduce((sum, e) => sum + e.price, 0);
              const totalIQD2 = IQD2.reduce((sum, e) => sum + e.price, 0);
              const totalIQD = totalIQD1 - totalIQD2;
              const classes = "p-4 border-r-2 border-[#344369] text-center ";

              return (
                <tr key={item.objectId}>
                  <td
                    className={`${
                      index === 0 ? "border-t-2 border-[#344369]" : ""
                    }`}
                  >
                    <Link
                      onClick={() => {
                        dispatch(getProduct());
                      }}
                      to={`/reports/${item.objectId}`}
                    >
                      <Typography
                        variant="small"
                        color="white"
                        className="font-normal font-tajawal text-center"
                      >
                        {item.name}
                      </Typography>
                    </Link>
                  </td>
                  <td
                    className={`${
                      index === 0 ? "border-t-2 border-[#344369]" : ""
                    } ${classes}`}
                  >
                    <div className="text-center">
                      <Chip
                        className="text-white font-tajawal text-center"
                        variant="ghost"
                        size="sm"
                        value={totalUSD.toLocaleString("en-US")}
                        color={totalUSD >= 0 ? "green" : "red"}
                      />
                    </div>
                  </td>
                  <td
                    className={`${
                      index === 0 ? "border-t-2 border-[#344369]" : ""
                    } ${classes}`}
                  >
                    <div className="text-center">
                      <Chip
                        className="text-white font-tajawal text-center"
                        variant="ghost"
                        size="sm"
                        value={totalIQD.toLocaleString("en-US")}
                        color={totalIQD >= 0 ? "green" : "red"}
                      />
                    </div>
                  </td>
                  <td
                    className={`${
                      index === 0 ? "border-t-2 border-[#344369]" : ""
                    } ${classes}`}
                  >
                    <div className="flex flex-col text-center">
                      <Typography
                        as="div"
                        variant="small"
                        color="white"
                        className="font-normal font-tajawal"
                      >
                        {formatDate(item.date)}
                      </Typography>
                    </div>
                  </td>

                  <td
                    className={`${
                      index === 0 ? "border-t-2 border-[#344369]" : ""
                    } ${classes}`}
                  >
                    <div className="flex flex-col text-center">
                      <Typography
                        as="div"
                        variant="small"
                        color="white"
                        className="font-normal font-tajawal"
                      >
                        <Button
                          onClick={async () => {
                            if (totalUSD === 0 && totalIQD === 0) {
                              await dispatch(deleteUser(item.objectId));
                              await dispatch(getNews());
                              setSuccess(true);
                            } else {
                              setSuccess(false);
                              setError("لا يمكن الحذف لعدم تسديد المبلغ ");
                            }
                          }}
                          color="red"
                          className="flex items-center gap-3 font-tajawal"
                          size="sm"
                        >
                          حذف
                        </Button>
                      </Typography>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between p-4">
        <Typography
          variant="small"
          color="white"
          className="font-normal font-tajawal"
        >
          صفحة {currentPage} من {Math.ceil(sortedData.length / itemsPerPage)}
        </Typography>
        <div className="flex gap-2">
          <Button
            className="font-tajawal"
            color="white"
            variant="outlined"
            size="sm"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            السابق
          </Button>
          <Button
            className="font-tajawal"
            color="white"
            variant="outlined"
            size="sm"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={
              currentPage === Math.ceil(sortedData.length / itemsPerPage)
            }
          >
            التالي
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}

export default Reports;
