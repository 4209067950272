// actions.js
import axios from "axios";
import { Url } from "../types/url";
import api from "../types/api";
import product from "../types/productApi";

export const getProjects = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_PROJECTS_START" });
    try {
      const res = await axios.get(Url + "/api/projects");
      dispatch({ type: "GET_PROJECTS_SUCCESS", payload: res.data });
    } catch (error) {
      dispatch({ type: "GET_PROJECTS_ERROR", payload: error.message });
    }
  };
};

export const getCategories = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_CATEGORIES_START" });
    try {
      const res = await axios.get(Url + "/api/categories");
      dispatch({ type: "GET_CATEGORIES_SUCCESS", payload: res.data });
    } catch (error) {
      dispatch({ type: "GET_CATEGORIES_ERROR", payload: error.message });
    }
  };
};

export const getNews = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_NEWS_START" });
    try {
      const res = await api.get();
      dispatch({ type: "GET_NEWS_SUCCESS", payload: res.data.results });
    } catch (error) {
      dispatch({ type: "GET_NEWS_ERROR", payload: error.message });
    }
  };
};

export const getProduct = () => {
  return async (dispatch) => {
    dispatch({ type: "GET_PRODUCT_START" });
    try {
      const res = await product.get();
      dispatch({ type: "GET_PRODUCT_SUCCESS", payload: res.data.results });
    } catch (error) {
      dispatch({ type: "GET_PRODUCT_ERROR", payload: error.message });
    }
  };
};

export const deleteProduct = (id) => {
  return async (dispatch) => {
    dispatch({ type: "DELETE_PRODUCT_START" });

    const BASE_URL = `https://parseapi.back4app.com/classes/product/${id}`;
    const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
    const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

    let config = {
      method: "delete",
      baseURL: BASE_URL,
      headers: {
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": API_KEY,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      if (response.status === 200) {
        dispatch({ type: "DELETE_PRODUCT_SUCCESS", payload: response.data });
      } else {
        throw new Error("Failed to delete the product");
      }
      return response;
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      dispatch({ type: "DELETE_PRODUCT_ERROR", payload: error.message });
      throw error;
    }
  };
};

export const deleteUser = (id) => {
  return async (dispatch) => {
    dispatch({ type: "DELETE_USER_START" });

    const BASE_URL = `https://parseapi.back4app.com/classes/users/${id}`;
    const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
    const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

    let config = {
      method: "delete",
      baseURL: BASE_URL,
      headers: {
        "X-Parse-Application-Id": APP_ID,
        "X-Parse-REST-API-Key": API_KEY,
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.request(config);
      if (response.status === 200) {
        dispatch({ type: "DELETE_USER_SUCCESS", payload: response.data });
      } else {
        throw new Error("Failed to delete the USER");
      }
      return response;
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      dispatch({ type: "DELETE_USER_ERROR", payload: error.message });
      throw error;
    }
  };
};

// export const getPrice = () => {
//   return async (dispatch) => {
//     dispatch({ type: "GET_PRICE_START" });
//     try {
//       const res = await axios.get(
//         "https://api.currencyfreaks.com/v2.0/rates/latest?apikey=f46b5d7abd714cd284838bcfb7dd1e7a"
//       );
//       dispatch({ type: "GET_PRICE_SUCCESS", payload: res.data.rates });
//     } catch (error) {
//       dispatch({ type: "GET_PRICE_ERROR", payload: error.message });
//     }
//   };
// };
