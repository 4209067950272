import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { Footer } from "./components/layouts/Footer";
import NavBar from "./components/layouts/Navbar";
import Home from "./components/page/home";
import { Login } from "./components/page/login";
import Form from "./components/page/form";
import "./App.css";

import { useDispatch } from "react-redux";
import { getNews, getProduct } from "./context/actions/getaction";
import AddForm from "./components/page/form/addForm";
import AddReports from "./components/page/reports/addReports";

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNews());
    dispatch(getProduct());
  }, [dispatch]);
  return (
    <div className="min-h-screen flex flex-col bady ">
      <BrowserRouter>
        {isLoggedIn && (
          <div className="sticky top-2 p-2 z-50">
            <NavBar onLogin={setIsLoggedIn} />
          </div>
        )}
        <div
          className={`flex-grow ${
            !isLoggedIn ? "flex items-center justify-center" : ""
          }`}
        >
          <Routes>
            {isLoggedIn ? (
              <>
                <Route path="/" element={<Home />} />
                <Route path="/form" element={<Form />} />
                <Route path="/add_Form/:id" element={<AddForm />} />
                <Route path="/reports/:id" element={<AddReports />} />
                <Route path="*" element={<Navigate to="/" />} />
              </>
            ) : (
              <Route
                path="/login"
                element={<Login onLogin={setIsLoggedIn} />}
              />
            )}
            {/* إعادة توجيه إلى صفحة تسجيل الدخول إذا لم يكن المستخدم مسجل الدخول */}
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
        {isLoggedIn && (
          <div className="px-2 text-center">
            <Footer />
          </div>
        )}
      </BrowserRouter>
    </div>
  );
}
