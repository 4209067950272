// src/api.js

import axios from "axios";

const BASE_URL = "https://parseapi.back4app.com/classes/product";
const APP_ID = "jBcsdUCAdIxPdSwIpH7bb0wvUXuSCkZOWd1bY7GX";
const API_KEY = "rwJiEgAXH9h3FlWmdzzNU7HJooTyZ3deG9r1VkFm";

const product = axios.create({
  baseURL: BASE_URL,
  headers: {
    "X-Parse-Application-Id": APP_ID,
    "X-Parse-REST-API-Key": API_KEY,
  },
});

export const getData = async () => {
  try {
    const response = await product.get();
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export default product;
