// Projects
const Projects = {
  loading: false,
  projects: [],
  error: null,
};

export const reducerGetProjects = (state = Projects, action) => {
  switch (action.type) {
    case "GET_PROJECTS_START":
      return { ...state, loading: true, error: null };
    case "GET_PROJECTS_SUCCESS":
      return { ...state, loading: false, projects: action.payload };
    case "GET_PROJECTS_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// Categories
const Categories = {
  loading: false,
  categories: [],
  error: null,
};

export const reducerGetCategories = (state = Categories, action) => {
  switch (action.type) {
    case "GET_CATEGORIES_START":
      return { ...state, loading: true, error: null };
    case "GET_CATEGORIES_SUCCESS":
      return { ...state, loading: false, categories: action.payload };
    case "GET_CATEGORIES_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

// News
const News = {
  loading: false,
  news: [],
  error: null,
};

export const reducerGetNews = (state = News, action) => {
  switch (action.type) {
    case "GET_NEWS_START":
      return { ...state, loading: true, error: null };
    case "GET_NEWS_SUCCESS":
      return { ...state, loading: false, news: action.payload };
    case "GET_NEWS_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const Product = {
  loading: false,
  product: [],
  error: null,
};
export const reducerGetProduct = (state = Product, action) => {
  switch (action.type) {
    case "GET_PRODUCT_START":
      return { ...state, loading: true, error: null };
    case "GET_PRODUCT_SUCCESS":
      return { ...state, loading: false, product: action.payload };
    case "GET_PRODUCT_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const DeleteProduct = {
  loading: false,
  delete: [],
  error: null,
};
export const reducerDeleteProduct = (state = DeleteProduct, action) => {
  switch (action.type) {
    case "DELETE_PRODUCT_START":
      return { ...state, loading: true, error: null };
    case "DELETE_PRODUCT_SUCCESS":
      return { ...state, loading: false, delete: action.payload };
    case "DELETE_PRODUCT_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const DeleteUser = {
  loading: false,
  user: [],
  error: null,
};
export const reducerDeleteUser = (state = DeleteUser, action) => {
  switch (action.type) {
    case "DELETE_USER_START":
      return { ...state, loading: true, error: null };
    case "DELETE_USER_SUCCESS":
      return { ...state, loading: false, user: action.payload };
    case "DELETE_USER_ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
